



























import Vue from "vue";
import data from "@/datatables/pos/paused-cashtray";
import datatable from "@/utils/datatable/components/datatable.vue";
export default Vue.extend({
  name: "pos-cashtray",
  components: {
    datatable,
  },
  data() {
    return {
      modal:false,
      serial:0,
      amount : "",
      data,
    };
  },
  methods:{
    openModal(serial:number){
      this.serial = serial
      this.modal= true
    },
    close(){
      this.$router.push({name : "close-cashtray" , params:{serial:this.serial.toString() , amount: this.amount}})
    }
  }
});
